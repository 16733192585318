.MuiAccordionSummary-content p {
    font-size: 16px !important;
    color: #fff !important;
    font-weight: 600 !important;
}
.MuiAccordionSummary-expandIconWrapper svg {
    stroke: #fff !important;
    stroke-width: 2px;
}
.panel_content{
    padding: 10px !important;
    border-radius: 6px !important;
    background: linear-gradient(91deg, #51A2BD -111.75%, rgba(81, 162, 189, 0.80) 53.32%, rgba(81, 162, 189, 0.30) 124.6%) !important;
}
.panel_content.Mui-expanded{
    border-radius: 6px 6px 0px 0px !important;
}
.panel_main {
    border-radius: 6px !important;
    box-shadow: none !important;
}
.accordion_expanded {
    border-radius: 0px 0px 6px 6px !important;
    background: linear-gradient(70deg, #51A2BD 22.57%, rgba(81, 162, 189, 0.70) 83.22%, rgba(81, 162, 189, 0.00) 109.41%) !IMPORTANT;
    border-top: 0 !important;
}
.resume_input input {
    /* padding: 10px !important; */
    background: #fff !important;
    border-radius: 4px !important;
}
.resume_input fieldset {
    display: none !important;
}
.skills_div_width{
    width: 100% !important;
    background: #fff !important;
    border-radius: 4px !important;
}
.hobby_main {
    padding-top: 30px !important;
}
#Profile_Header-label, .DOB label, label#Objective_Header-label, label#Skill_Header-label, label#WorkExperience_Header-label,label#Education_Header-label, label#Certification_Header-label,label#Awards_Headers-label, label#Project_Header-label, label#Language_Header-label,label#Hobby_Header-label, label#Reference_Headers-label{
    top: -10px !important;
    left: -10px !important;
    font-weight: 600 !important;
    color: #fff !important;
}
.resume_input label.MuiInputLabel-shrink{
    top: -10px !important;
    left: -10px !important;
    font-weight: 600 !important;
    color: #fff !important;
}
.skills_div_width:focus-visible
{
    border: none !important;
    outline: none !important;
}
.resume_input label.Mui-focused, label#lbl_level.Mui-focused{
    top: -10px !important;
    left: -10px !important;
}
.personalInfo_div, .objectice_div, .skills_div,.education_div, .certification_div, .awards_div, .project_div, .language_div, .reference_div{
    padding-top: 30px !important;
}
.work_div_main {
    padding-top: 30px !important;
}
textarea#Profile_Address {
    padding: 10px;
    border-radius: 6px;
    background: #fff;
}
.address_profile div {
    padding: 0;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    border-radius: 4px;
    background: #fff;
}
.css-o-1k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    padding-right: 25px !important;
}
.DOB input {
    padding: 10px 25px 10px 10px !important;
}

/* .css-q8hpuo-MuiFormControl-root {
    background: #fff !important;
    border-radius: 4px !important;
} */
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px !important;
}
.css-urj5pv-MuiGrid-root, .css-12xeub0-MuiGrid-root{
    padding-top: 25px !important;
}
.add_btn_resume.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
    padding: 8px 10px !important;
    cursor: not-allowed !important;
}
.add_btn_resume{
    font-weight: 600 !important;
    background: #52a2bd !important;
    color: #fff !important;
    padding: 8px 10px !important;
}
.MuiBox-root.css-kqk1w7 {
    background: #FFF;
    border: 0 !important;
}
.css-i4bv87-MuiSvgIcon-root {
    font-size: 15px !important;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconSmall.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault.css-i4bv87-MuiSvgIcon-root {
    font-size: 16px !important;
}
.resume_input {
    border: 1px solid #e1e2eb !important;
    border-radius: 6px !important;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    border: 1px solid #e1e2eb;
}
.work_btn, .education_btn, .certification_btn, .awards_btn, .project_btn, .reference_btn {
    padding-top: 0px !important;
}
.dates_work, .education_date, .certification_switch, .certification_date, .awards_date, .awards_awarder, .project_date, .hobby_field, .add_hobby {
    padding-top: 25px !important;
}
.MuiBox-root.css-5jz7vm {
    border: 0 !important;
    background: #fff !important;
}
.ant-float-btn-icon {
    color: #fff !important;
}
.ant-float-btn-body {
    background: #51A2BD !important;
}
.resume_input label.Mui-focused, label#lbl_level.Mui-focused{
    font-weight: 600 !important;
    color: #fff !important;
}
.resume_input.DOB {
    padding: 1px !important;
    background: #fff !important;
    border-radius: 4px !important;
}

.css-p86gk6-MuiPaper-root-MuiCard-root {
    background-color: #7bb7cb !important;
    border: 2px solid #fff;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    color: #fff !important;
    font-weight: 600 !important;
}
.btn_work{
    font-weight: 600 !important;
    background: #0e6a89 !important;
    color: #fff !important;
    padding: 8px 10px !important;
}
.responseMessage {
    text-align: center;
    margin-top: 15px;
    font-weight: 600;
    float: left;
    width: 100%;
}