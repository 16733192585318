.slider_item1 {
    background: linear-gradient(90deg, #4890BE 0.06%, #57ADC1 78.28%, #5FBCC2 117.24%);
    position: relative !important;
    padding: 2rem !important;
}
.sliderText1.carousel-caption {
    position: unset !important;
    padding: 0 !important;
    float: left;
    width: 50%;
    line-height: 1.7;
    text-align: left !important;
}
.right_img {
    float: right;
    width: 50%;
}
.right_img img {
    width: 65% !important;
    height: auto !important;
    margin-left: auto;
}
.slider_main_1 {
    display: flex;
    align-items: center;
}
a.carousel-control-prev, a.carousel-control-next {
    display: none;
}
.sliderText1 h3 {
    font-size: 45px ;
    font-weight: bold;
}
.sliderText1 span{
    font-size: 60px !important;
}
.sliderText1 p{
    font-size: 20px !important;
    color: #fff !important;
}
.video_right {
    float: right;
    width: 50%;
    padding: 2rem;
}
.video_left {
    float: left;
    width: 50%;
    padding: 2rem;
}
.video_main {
    float: left;
    width: 100%;
}
h3.template_head {
    font-size: 25px !important;
    position: relative;
    font-weight: 600;
}
h3.template_head::before {
    content: "";
    position: absolute;
    top: 35px;
    left: 0;
    width: 10%;
    height: 6px;
    background-color: #51A2BD;
    border-radius: 5px;
}
h3.template_head::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 34px;
    left: 30px;
    width: 8px;
    height: 8px;
    background-color: #FF6666;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 2px rgba(255,255,255,1);
}

a.btn.btn-primary.learn_more {
    border-radius: 5px !important;
    background: #51A2BD !important;
    border: 0 !important;
    float: right !important;
    padding: 12px 15px !important;
    font-size: 16px !important;
}
.video_right p {
    font-size: 16px;
    color: #000 !important;
    line-height: 1.8;
    text-align: justify;
}
.our_work_main {
    float: left;
    width: 100%;
    padding: 0 2rem;
    margin-bottom: 50px;
}
.video_section {
    float: left;
    width: 100%;
}


h3.our_work_head {
    font-size: 25px !important;
    position: relative;
    font-weight: 600;
}
h3.our_work_head::before {
    content: "";
    position: absolute;
    top: 35px;
    left: 0;
    width: 5%;
    height: 6px;
    background-color: #51A2BD;
    border-radius: 5px;
}
h3.our_work_head::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 34px;
    left: 30px;
    width: 8px;
    height: 8px;
    background-color: #FF6666;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 2px rgba(255,255,255,1);
}
.contactMain {
    float: left;
    width: 100%;
    padding: 0 2rem;
}
h3.Contact_head {
    font-size: 25px !important;
    position: relative;
    font-weight: 600;
    text-align: center;
}
h3.Contact_head::before {
    content: "";
    position: absolute;
    top: 35px;
    left: 47%;
    width: 5%;
    height: 6px;
    background-color: #51A2BD;
    border-radius: 5px;
}
h3.Contact_head::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 34px;
    left: 49.6%;
    width: 8px;
    height: 8px;
    background-color: #FF6666;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 2px rgba(255,255,255,1);
}
.contactForm {
    float: left;
    width: 100%;
}
form.landing_Contact > div > input {
    margin-bottom: 15px;
}
.nameRow {
    display: flex;
    gap: 15px;
}
form.landing_Contact {
    text-align: center !important;
    float: none;
    max-width: 60%;
}
input.firstName {
    float: left;
    width: 50%;
    border-radius: 5px !important;
    border: 1px solid #AEAEAE !important;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #C2C2C2 !important;
}
input.lastName {
    float: right;
    width: 50%;
    border-radius: 5px !important;
    border: 1px solid #AEAEAE !important;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #C2C2C2 !important;
}
input.EmailID {
    float: left;
    width: 100%;
    border-radius: 5px !important;
    border: 1px solid #AEAEAE !important;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #C2C2C2 !important;
}
textarea.messageInput {
    float: left;
    width: 100%;
    border-radius: 5px !important;
    border: 1px solid #AEAEAE !important;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #000 !important;
}
button.submitBtn {
    float: inherit;
    width: 80%;
    border-radius: 5px !important;
    background: #51A2BD !important;
    border: 0 !important;
    margin-top: 15px !important;
    font-size: 16px !important;
    color: #fff !important;
    font-weight: 600 !important;
}
.contactForm {
    background-image: url(../images/Group\ 355.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
}
footer.footermain {
    float: left;
    width: 100%;
    background: #51A2BD;
    padding: 1rem 2rem;
    margin-top: 3rem;
}
.footer_main p {
    font-size: 16px;
    margin-bottom: 0 !important;
    color: #fff !important;
    letter-spacing: 2px;
}
.footer_main p a {
    color: #fff !important;
    text-decoration: none !important;
    font-weight: 600;
}
.getStarted {
    border-radius: 5px !important;
    background: #51A2BD !important;
    padding: 12px 15px !important;
    color: #fff !important;
    font-weight: 600 !important;
    text-decoration: none !important;
}
.ml-auto.header-btn.navbar-nav {
    align-items: center;
    gap: 10px;
}

.loginBTn
{
    color: #51A2BD !important;
    font-size: 16px !important;
    text-decoration: none !important;
}

@media screen and (max-width: 1024px) and (min-width: 768px) { 
   
    .sliderText1 h3 {
        font-size: 30px;
    }
    .sliderText1 span {
        font-size: 45px !important;
    }
    .sliderText1 p {
        font-size: 16px !important;
        color: #fff !important;
    }
    .video_right p {
        font-size: 14px;
        color: #000 !important;
    }
    h3.template_head {
        font-size: 22px !important;
    }
    h3.template_head::before {
        top: 32px;
        width: 16%;
    }
    h3.template_head::after {
        top: 31px;
        left: 34px;
    }
    a.btn.btn-primary.learn_more {
        font-size: 12px !important;
    }
    h3.Contact_head {
        font-size: 22px !important;
    }
    h3.Contact_head::before {
        top: 32px;
        left: 46%;
        width: 7%;
    }
    h3.Contact_head::after {
        top: 32px;
        left: 49%;
    }
    .footer_main p {
        font-size: 14px;
    }
    button.submitBtn {
        font-size: 14px !important;
    }
    input.firstName, input.lastName, input.EmailID, textarea.messageInput {
        font-size: 14px !important;
    }     
    form.landing_Contact {
        max-width: 75%;
    }
}


@media screen and (max-width: 767px) and (min-width: 450px) { 
   
    .sliderText1 h3 {
        font-size: 20px;
    }
    .sliderText1 span {
        font-size: 25px !important;
    }
    .sliderText1 p {
        font-size: 14px !important;
    }
    .video_right p {
        font-size: 12px;
        color: #000 !important;
    }
    h3.template_head {
        font-size: 18px !important;
    }
    h3.template_head::before {
        top: 28px;
        width: 16%;
        height: 4px;
    }
    h3.template_head::after {
        top: 26px;
        left: 22px;
        height: 6px;
        width: 6px;
    }
    a.btn.btn-primary.learn_more {
        font-size: 12px !important;
        padding: 10px 12px !important;
    }
    h3.Contact_head {
        font-size: 18px !important;
    }
    h3.Contact_head::before {
        top: 28px;
        left: 46%;
        width: 7%;
        height: 4px;
    }
    h3.Contact_head::after {
        top: 27px;
        left: 49%;
        width: 5px;
        height: 5px;
    }
    .footer_main p {
        font-size: 12px;
    }
    button.submitBtn {
        font-size: 12px !important;
    }
    input.firstName, input.lastName, input.EmailID, textarea.messageInput {
        font-size: 12px !important;
    }     
    form.landing_Contact {
        max-width: 80%;
    }
}

@media screen and (max-width: 449px) and (min-width: 280px) { 

    .video_left {
        width: 100%;
    }
    .video_right {
        width: 100%;
    }
   
    .sliderText1 h3 {
        font-size: 14px;
    }
    .sliderText1 span {
        font-size: 18px !important;
    }
    .sliderText1 p {
        font-size: 10px !important;
        line-height: 1.5;
    }
    .video_right p {
        font-size: 10px;
        color: #000 !important;
    }
    h3.template_head {
        font-size: 12px !important;
    }
    h3.template_head::before {
        top: 20px;
        width: 10%;
        height: 3px;    
    }
    h3.template_head::after {
        top: 20px;
        left: 18px;
        height: 4px;
        width: 4px;    
    }
    a.btn.btn-primary.learn_more {
        font-size: 12px !important;
        padding: 10px 12px !important;
    }
    h3.Contact_head {
        font-size: 12px !important;
    }
    h3.Contact_head::before {
        top: 20px;
        left: 45%;
        width: 10%;
        height: 3px;
    }
    h3.Contact_head::after {
        top: 20px;
        left: 50%;
        width: 4px;
        height: 4px;    
    }
    .footer_main p {
        font-size: 10px;
        letter-spacing: normal;
    }
    button.submitBtn {
        font-size: 10px !important;
    }
    input.firstName, input.lastName, input.EmailID, textarea.messageInput {
        font-size: 10px !important;
    }     
    form.landing_Contact {
        max-width: 100%;
    }
    .contactForm {
        background-image: none;
    }
    form.landing_Contact.container {
        padding-top: 20px !IMPORTANT;
    }
}