.templateDiv{
    transform-origin: 0 0;
    transform: scale(.22,.23);
    height: 450%;
    width: 450%;
}

.resumeViewpreview{
    height: 350px;
    overflow: hidden;
}
.CardMain .MuiCardHeader-root.css-14ryps-MuiCardHeader-root {
    margin-bottom: 0 !important;
    padding: 10px 15px !important;
    border-bottom: none !important;
}
.CardMain .MuiCardContent-root.css-1hizqsf-MuiCardContent-root {
    padding: 5px !important;
    border-bottom: 2px solid #e2e2e2 !important;
}
.CardMain {
    background-color: #F8F8F8 !important;
    box-shadow: none !important;
    filter: drop-shadow(0px 0px 3px #ababab) !important;
    border-radius: 7px !important;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-9.MuiGrid-grid-xl-6.css-18yqyz6-MuiGrid-root {
    max-width: 100% !important;
    padding-top: 0 !important;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.css-1e7gr6b-MuiGrid-root {
    display: block !important;
}
.label-icon-container {
    display: flex;
    align-items: center;
    gap: 5px;
}
.chooseTemplateBtn {
    text-align: center;
}

.chooseTemplateBtn button {
    border: 2px solid #fff !important;
    padding: 10px 15px !important;
    border-radius: 30px !important;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}
.chooseTemplateBtn {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 20%;
    opacity: 0;
}
.resumeViewpreview {
    position: relative;
}
  .CardMain .CardContentMain {
    position: relative; 
  }
  
  .CardContentMain::before{
    content: ""; 
    position: absolute; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0); 
    opacity: 0; 
    transition: opacity 0.3s; 
    z-index: 99999;
  }
  .CardContentMain:hover::before{
    background-color: rgba(0, 0, 0, 0.5); 
    opacity: 1;
  }
  
  .CardMain .CardContentMain:hover .chooseTemplateBtn{
    opacity: 1;
    transition: opacity 0.3s;
    z-index: 9999999999999;
  }
  .home_template_grp{
    max-width: 100% !important;
  }
  .page-content .container{
    margin: 0;
    max-width: 100%;
    padding: 0;
  }
  .page-content{
    width: 100% !important;
    min-height: fit-content !important;
  }
  .yoggboxst table {
    height: 100% !important;
}
.CardContentImage div{
        width: 100% !important;
    min-height: fit-content !important;
}