.editor-class {
  background-color:white;
  padding: 1rem;
  border: 1px solid #ccc;
  height: 150px;
  overflow-y: auto;
}

.public-DraftStyleDefault-block{
    margin: 0px;
}