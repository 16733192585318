.search_bar{
    background: transparent !important;
    width: 60% !important;
}
/* .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    width: 100%;
    height: 2.8rem !important;
    background: #f5f5f5 !important;
    outline: none;
    border: none;
    padding: 0 3.5rem 0 0.5rem !important;
    font-size: 1rem;
    position: relative;
} */
.search_feedback_button {
    width: 3.5rem;
    height: 2.8rem;
    /* margin-left: -3.5rem !important; */
    background: none !important;
    border: none;
    outline: none;
    box-shadow: none !important;
    position: absolute !important;
    right: 10px !important;

}
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    border: 0 !important;
}
/* .css-q8hpuo-MuiFormControl-root {
    width: 40% !important;
} */
.css-13shxg4-MuiGrid-root{
    padding-top: 20px !important;
}
/* .css-sghohy-MuiButtonBase-root-MuiButton-root {
    background: #106a89 !important;
} */
.feedback_accordion {
    border-radius: 6px;
    background: none;
    box-shadow: none !important;
}
.feedback_accordion.Mui-expanded {
    border-radius: 6px;
    background: #fff;
    box-shadow: none;
}
.feedback_summary {
    padding: 10px !important;
    border-radius: 6px !important;
    background: linear-gradient(91deg, #51A2BD -111.75%, rgba(81, 162, 189, 0.80) 53.32%, rgba(81, 162, 189, 0.30) 124.6%) !important;
}
.feedback_summary.Mui-expanded{
    border-radius: 6px 6px 0px 0px !important;
}
input#search_feedback{
    height: 2.8rem;
    background: #f5f5f5 !important;
    outline: none;
    border: none;
    border-radius: 1.625rem;
    padding: 0 3.5rem 0 1.5rem;
    font-size: 1rem;
    color: #333 !important;
    position: relative;
}
.search_bar fieldset{
    border: 0 !important;
}
.feedback_drop{
    width: 100px !important;
}
.btn-choose {
    background: #52a2bd !important;
}