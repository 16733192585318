.userTemplateView1{
    transform-origin: 0 0;
    transform: scale(.24,.22);;
    height: 403%;
    width: 403%;
}

.userAllResumeDiv1{
    height: 250px;
    overflow: hidden;
}