.resumeViewpreview {
    
}

.resumeDiv{
    -webkit-transform:scale(.2,.2);
    -ms-transform:scale(.2,.2);
    transform:scale(.2,.2);
    -webkit-transform-origin:0 0; 
    -ms-transform-origin:0 0; 
    transform-origin:0 0; 
    width: 1000px;
}