/* .makeStyles-navigationBar-2 .css-imwso6-MuiBottomNavigationAction-label.Mui-selected {
    color: #fff !important;
    font-size: 0.90rem !important;
} */
button#template_btn_main span.Mui-selected{
    color: #fff !important;
    font-size: 0.90rem !important;
}
.searchbar_main {
    display: inline-flex !important;
    justify-content: flex-end !important;
    gap: 20px !important;
    align-items: center !important;
}
.createTemplate_btn {
    padding: 10px !important;
    border-radius: 3px !important;
    background: rgb(81 162 189 / 40%) !important;
    box-shadow: none !important;
    color: #228fb4 !important;
    font-size: 14px !important;
}
.search_bar input#mui-2 {
    padding: 10px !important;
    font-size: 12px !important;
    width: 300px;
}
.search_btn {
    padding: 10px 20px !important;
    font-size: 10px;
}
.search_btn {
    padding: 10px 20px !important;
    font-size: 10px  !important;
    background: #52a2bd !important;
    box-shadow: none !important;
}
.template_table_head {
    background-color: #51A2BD !important;
    color: #fff !important;
    font-size: 12px !important;
}
.template_table_data {
    font-size: 12px !important;
}

.searchBar {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  
  #searchQueryInput {
    width: 70% !important;
    height: 2.8rem;
    background: #f5f5f5 !important;
    outline: none;
    border: none;
    border-radius: 1.625rem;
    padding: 0 3.5rem 0 1.5rem;
    font-size: 1rem;
  }
  
  #searchQuerySubmit {
    width: 3.5rem;
    height: 2.8rem;
    margin-left: -3.5rem;
    background: none !important;
    border: none;
    outline: none;
  }
  
  #searchQuerySubmit:hover {
    cursor: pointer;
  }

  button#main_logo.Mui-selected {
    color: #1976d2;
    padding: 0 !important;
    align-items: baseline !important;
}
button#main_logo {
    padding: 0 !important;
    align-items: baseline !important;
}
.css-1c2usl7 {
    padding: 0 24px !important;
    height: 100vh !important;
}
button#template_btn_main {
    padding: 0 !important;
}
h3.template_head_main::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 34px;
    left: 30px;
    width: 8px;
    height: 8px;
    background-color: #FF6666;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 2px rgba(255,255,255,1);
}
h3.template_head_main::before {
    content: "";
    position: absolute;
    top: 35px;
    left: 0;
    width: 20%;
    height: 6px;
    background-color: #51A2BD;
    border-radius: 5px;
}
h3.template_head_main {
    font-size: 25px !important;
    position: relative;
}
.addTemplateDialog_main {
    background: #51A2BD !important;
}
.videoDemo .modal-dialog {
    top: 7%;
    right: 0;
    margin-right: 0;
    margin-top: 0;
    max-width: 650px !important;
    width: 100% !important;
}
.updatevideoBtn
{
padding: 10px !important;
border-radius: 3px !important;
background: rgb(81 162 189 / 40%) !important;
box-shadow: none !important;
color: #228fb4 !important;
font-size: 14px !important;
}
.videoModalbtn{
font-size: 14px !important;
    background-color: #51A2BD !important;
    text-transform: capitalize !important;
    padding: 8px 15px !important;
    box-shadow: none !important;
}
p.slider_1_head {
    font-size: 14px;
    margin-bottom: 0;
}
.slider_content {
    padding: 15px 15px 3px 15px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(174,174,174,1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(174, 174, 174, 1);
    box-shadow: 0px 0px 5px 1px rgba(174,174,174,1);
}
.video_section_content {
    padding: 15px 15px 15px 15px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(174,174,174,1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(174, 174, 174, 1);
    box-shadow: 0px 0px 5px 1px rgba(174,174,174,1);
}
.slider_1_heading {
    margin-bottom: 12px;
}
.slider_1_below_head {
    margin-bottom: 12px;
}
.contents_update {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.video_section_content_main {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.video_section_head p, .slider_head_main p {
    font-size: 16px;
    font-weight: 600;
    color: #333;
}
.modal-content {
    border-radius: 0 !important;
}
@media screen and (max-width: 1399px) and (min-width: 1200px) 
{
    .template_table_left{
        flex-basis: 30% !important;
        max-width: 30% !important; 
    }
    .searchbar_main{
        flex-basis: 70% !important;
        max-width: 70% !important;
    }
    .createTemplate_btn {
        width: 20% !important;
    }
    .searchBar {
        width: 55%;
    }
    h3.template_head_main::before {
        width: 18%;
    }
    
}
@media screen and (max-width: 1199px) and (min-width: 1024px) 
{
    .template_table_left{
        flex-basis: 30% !important;
        max-width: 30% !important; 
    }
    .searchbar_main{
        flex-basis: 70% !important;
        max-width: 70% !important;
    }
    .createTemplate_btn {
        width: 20% !important;
        font-size: 12px !important;
        padding: 12px 10px !important;
    }
    .searchBar {
        width: 55%;
    }
    h3.template_head_main::before {
        width: 18%;
    }
    
}
@media screen and (max-width: 1023px) and (min-width: 768px) 
{
    .template_table_left{
        flex-basis: 20% !important;
        max-width: 20% !important; 
    }
    .searchbar_main{
        flex-basis: 80% !important;
        max-width: 80% !important;
    }
    .createTemplate_btn {
        width: 25% !important;
        font-size: 12px !important;
        padding: 12px 10px !important;
    }
    .searchBar {
        width: 47%;
    }
    h3.template_head_main::before {
        width: 50% !important;
    }
}
@media screen and (max-width: 767px) and (min-width: 550px) 
{
    h3.template_head_main {
        font-size: 16px !important;
    }
    .template_table_left{
        flex-basis: 20% !important;
        max-width: 20% !important; 
    }
    .searchbar_main{
        flex-basis: 80% !important;
        max-width: 80% !important;
        flex-wrap: wrap;
    }
    .createTemplate_btn {
        width: 30% !important;
        font-size: 10px !important;
        padding: 12px 10px !important;
    }
    .searchBar {
        width: 65%;
    }
    h3.template_head_main::before {
        width: 50% !important;
    }
    #searchQueryInput {
        width: 80% !important;
        height: 2rem;
        font-size: 12px;
    }
}
@media screen and (max-width: 549px) and (min-width: 400px) 
{
    h3.template_head_main {
        font-size: 16px !important;
    }
    .template_table_left{
        flex-basis: 30% !important;
        max-width: 30% !important; 
    }
    .searchbar_main{
        flex-basis: 70% !important;
        max-width: 70% !important;
        flex-direction: column !important;
        gap: 5px !important;
        align-items: flex-end !important;
    }
    .createTemplate_btn {
        width: 40% !important;
        font-size: 10px !important;
        padding: 12px 10px !important;
    }
    button#searchQuerySubmit {
        padding: 0 !important;
        position: absolute;
    }
    .searchBar {
        width: 100%;
    }
    h3.template_head_main::before {
        width: 50% !important;
    }
    #searchQueryInput {
        width: 80% !important;
        height: 2rem;
        font-size: 12px;
        position: relative;
    }
}
@media screen and (max-width: 399px) and (min-width: 280px) 
{
    h3.template_head_main {
        font-size: 16px !important;
    }
    .template_table_left{
        flex-basis: 40% !important;
        max-width: 40% !important; 
    }
    .searchbar_main{
        flex-basis: 60% !important;
        max-width: 60% !important;
        flex-direction: column !important;
        gap: 5px !important;
        align-items: flex-end !important;
    }
    .createTemplate_btn {
        width: 80% !important;
        font-size: 10px !important;
        padding: 12px 10px !important;
    }
    button#searchQuerySubmit {
        padding: 0 !important;
        position: absolute;
    }
    .searchBar {
        width: 100%;
    }
    h3.template_head_main::before {
        width: 50% !important;
    }
    #searchQueryInput {
        width: 95% !important;
        height: 2rem;
        font-size: 12px;
        position: relative;
    }
}