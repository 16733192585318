.menubar_main, .menubar_navigationHandler{
    background-color: #51A2BD !important;
    box-shadow: none !important;
    color: #fff !important;
}
.layout_setting_btn svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    font-size: 26px !important;
}
span.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary.MuiIconButton-sizeMedium.css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root {
    color: #fff !important;
}
.layout_setting_btn {
    color: #fff !important;
    
}
.menubar_navigationHandler button {
    padding: 0 !important;
    align-items: flex-start !important;
}
.home_main{
    display: block !important;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.home_main.css-i18n21 {
    display: block !important;
}
button#template_btn_main {
    padding: 0 !important;
}
@media screen and (max-width: 649px) and (min-width: 500px) { 

    .header_name{
        font-size: 14px !important;
        margin-right: 5px !important;
    }
    button#template_btn_main span {
        font-size: 10px !important;
        padding: 8px 20px;
    }
    button#main_logo img {
        width: 65%;
        height: auto;
    }
    

}
@media screen and (max-width: 499px) and (min-width: 410px) { 

    .header_name{
        font-size: 14px !important;
        margin-right: 5px !important;
    }
    button#template_btn_main span {
        font-size: 10px !important;
        padding: 8px 20px;
    }
    button#main_logo img {
        width: 65%;
        height: auto;
    }
    

}
@media screen and (max-width: 409px) and (min-width: 280px) { 

    .header_name{
        display: none ;
        font-size: 12px !important;
        margin-right: 5px !important;
    }
    button#template_btn_main span {
        font-size: 10px !important;
        padding: 8px 20px;
    }
    button#main_logo img {
        width: 65%;
        height: auto;
    }
    

}