.preview {
    -webkit-transform:scale(.97,1);
    -ms-transform:scale(.97,1);
    transform:scale(.97,1);
    -webkit-transform-origin:0 0; 
    -ms-transform-origin:0 0; 
    transform-origin:0 0; 
}

.draggableDiv{
  position: absolute;
  width: 104%;
  cursor: grab;
}

.draggableDiv:active{
  cursor: grabbing;
}

.hrPageBreak{
  margin: 0px;
   border: 1px dotted green;
}

.hrPageBreakError{
  margin: 0px;
   border: 1px solid red;
}



@media print {
  footer {
    position: fixed;
    bottom: 0;
  }

  @page{
    size: A4;
    margin-top: 50px;
}


@page:first{
    margin-top: 0px;
}
}