html, body, body > .container-fluid {
    height: 100%;
}

body {
    font-size: 14px;
    line-height: 1.7857142857;
    -webkit-font-smoothing: antialiased;
    font-family: "Montserrat", sans-serif !important;
    letter-spacing: 0.5px;
}

/*.container-fluid {
    padding-left: 70px !important;
    padding-right: 70px !important;
    width: 100% !important;
}
*/
p {
    color: #758294;
}

.row.full-height {
    min-height: 100%;
}

.ds-vertical-align {
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.right_image{
    height: 100vh;
}
.col.right {
    background: #f5f5f5;
    width: 50%;
}

.col.left {
    width: 50%;
    background-color: #fff !important;
}
.loginMainContainer{
    display: flex;
}
.MagicCVlogo{
    text-align: left;
}
.welcometext{
    text-align: left;
}
.col.left .inner {
    padding: 100px 15px 20px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}



/* .col.right {
    background: linear-gradient(#fcfcfc, #f0f0f0);
    height: 100vh;
    width: 100%;
} */

.drctry_service_images_h {
    width: 100%;
    padding: 10%;
    float: left;
}

.hjdd {
    color: #4e3a38;
    font-size: 13px;
}

.col.right h3 {
    margin-bottom: 15px;
}

    .col.right h3 img.icon {
        margin-right: 10px;
        margin-top: -2px;
    }

.col.right button {
    margin-top: 5px;
}

.divider {
    position: relative;
    text-transform: uppercase;
    font-size: 11px;
    color: #7e7e7e;
    margin: 10px 0 40px 0;
}

    .divider span {
        position: relative;
        z-index: 1;
        background: #fff;
        padding: 0 5px;
        letter-spacing: 2px;
    }

    .divider:after {
        content: '';
        height: 1px;
        width: 100%;
        background: #e1e1e1;
        position: absolute;
        top: 50%;
        left: 0;
    }

.carousel-item img {
    height: 100%;
    width: 100%;
}

h1, h2, h3, h4, h5 {
    font-weight: 700;
    color: #171933;
}

h1 {
    font-size: 50px;
    line-height: 60px;
    font-weight: 800;
    letter-spacing: -0.063rem;
    margin-bottom: 50px;
}

h3 {
    letter-spacing: 0rem;
    font-size: 18px;
    line-height: 26px;
}

.inner a {
    color: #e1463b;
    font-size: 13px;
}

img.logo {
    position: absolute;
    top: 11%;
    left: 42%;
    margin-left: -90px;
    max-width: 40%;
}

.form_group_12 {
    /* border: 1px solid #e7e7e7;
    padding: 5px 10px 5px 43px; */
    float: left;
    position: relative;
    width: 100%;
}

.form-group.form_group_12 i {
    position: absolute;
    left: 8px;
    color: #ff7a7f;
    top: 11px;
    font-size: 22px;
}

.form-group.form_group_12 label {
    margin-bottom: 0px;
    font-size: 11px;
    color: #7390df;
    font-weight: normal;
}

.form-group.form_group_12 input {
    border: 0px !important;
    box-shadow: none !important;
    padding: 0px !important;
    height: 25px !important;
    font-weight: normal;
}

form {
    text-align: left;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    border: 1px solid #e1e2eb;
}

.form-check {
    display: inline-block;
}

.form-check-input {
    margin-top: 7px;
}

.form-group p {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}
.welcometext h3 {
    font-size: 34px;
    margin-bottom: 0;
}
.welcometext span {
    font-size: 14px;
}
.forgot_link {
    font-size: 14px !important;
    padding: 0 !important;
    text-transform: capitalize !important;
    margin-bottom: 15px !important;
    color: #51A2BD !important;
    font-weight: 600 !important;
}

#loginCode {
    width: 100%;
    padding: 15px 8px !important;
    border: 1px solid #e1e2eb !important;
    background-color: #fff;
    color: #171933;
    caret-color: #a5a6b5;
    height: 49px;
    font-size: 14px;
    font-size: 1em;
    line-height: 29px;
    line-height: 2.0714285714em;
    -webkit-box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.20);
    -moz-box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.20);
    box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.20);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    line-height: 29px;
    line-height: 2.0714285714rem;
}
#password {
    width: 100%;
    padding: 15px 8px !important;
    border-right: 1px solid #e1e2eb !important;
    background-color: #fff;
    color: #171933;
    caret-color: #a5a6b5;
    height: 49px;
    font-size: 14px;
    font-size: 1em;
    line-height: 29px;
    line-height: 2.0714285714em;
    -webkit-box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.20);
    -moz-box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.20);
    box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.20);
    line-height: 29px;
    line-height: 2.0714285714rem;
}

    input:not([type="checkbox"]):not([type="range"]):not([type="file"]):not([type="submit"]):not([type="radio"]):focus,
    input:not([type="checkbox"]):not([type="range"]):not([type="file"]):not([type="submit"]):not([type="radio"]):hover:focus {
        border-color: #7a97ff;
        caret-color: #7a97ff;
        color: #171933;
    }

    input:not([type="checkbox"]):not([type="range"]):not([type="file"]):not([type="submit"]):not([type="radio"]):hover {
        border-color: #ced1e0;
    }

.field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
}

.container {
    padding-top: 50px;
    margin: auto;
}

button.btn {
    color: #fff;
    background-color: #3249c8;
    font-size: 1em;
    font-weight: 700;
    /*padding: 13px 60px;*/
    text-align: center;
    border: none;
    position: relative;
    display: inline-block;
    -webkit-box-shadow: 0 2px 4px 0 rgba(100, 105, 124, 0.24);
    -moz-box-shadow: 0 2px 4px 0 rgba(100, 105, 124, 0.24);
    box-shadow: 0 2px 4px 0 rgba(100, 105, 124, 0.24);
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    -ms-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    transition: background-color 0.2s;
    line-height: 23px;
    line-height: 1.6428571429rem;
}

/*button:hover {
    background-color: #29337d;
    padding: 13px 30px;
    cursor: pointer;
    text-decoration: none !important;
}*/

button:after {
    /*content: '';*/
    background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20width%3D%2213%22%20height%3D%227%22%20viewBox%3D%220%200%2013%207%22%3E%3Cdefs%3E%3Cpath%20id%3D%22a%22%20d%3D%22M9.685%200L8.97.694l2.184%202.309H0v1.009h11.135L8.97%206.306%209.685%207%2013%203.5z%22/%3E%3C/defs%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cmask%20id%3D%22b%22%20fill%3D%22%23fff%22%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22/%3E%3C/mask%3E%3Cg%20fill%3D%22%239DB8FA%22%20mask%3D%22url%28%23b%29%22%3E%3Cpath%20d%3D%22M0-3h14v14H0z%22/%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    position: absolute;
    height: 7px;
    width: 13px;
    margin: -3px 0 0;
    top: 50%;
    line-height: 22px;
    line-height: 1.5714285714rem;
    font-size: 14px;
    font-size: 1rem;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    right: 20px;
}

button:hover:after {
    right: 15px;
}

button[type="submit"] {
    margin: 5px 0;
    font-size: 16px;
    font-weight: 600;
    background-color: #51A2BD;
    text-transform: capitalize;
    padding: 10px !important;
    box-shadow: none !important;
}
button[type="submit"]:hover{
    background-color: #51A2BD ;
    box-shadow: none !important;
}
button.google_btn div {
    width: auto !important;
    justify-content: center;
    gap: 5px;
}
button.google_btn{
    background: none !important;
    
    box-shadow: none !important;
}

a.set_otp {
    color: #f15449;
    font-size: 13px;
    text-decoration: none;
    font-weight: 600;
}

.equaze_login:hover {
    color: #fff !important;
}

button.btn-outline {
    color: #171933;
    background-color: transparent;
    font-size: 1em;
    font-weight: 700;
    padding: 12px 29px;
    text-align: left;
    border: 1px solid #171933;
    position: relative;
    display: inline-block;
    -webkit-box-shadow: 0 2px 4px 0 rgba(100, 105, 124, 0.04);
    -moz-box-shadow: 0 2px 4px 0 rgba(100, 105, 124, 0.04);
    box-shadow: 0 2px 4px 0 rgba(100, 105, 124, 0.04);
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    line-height: 23px;
    line-height: 1.6428571429rem;
}

button.btn-small {
    font-size: 13px;
    line-height: 19px;
    padding: 5px 20px !important;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    text-transform: uppercase;
}

    button.btn-small:after {
        display: none;
    }

button.btn-google {
    background: url('../images/Group\ 6.svg') 20px center #fff no-repeat;
    color: #171933;
}

    button.btn-google:after {
        display: none;
    }







.power-ribbon-top-left {
    top: -6px;
    left: -9px;
}

.power-ribbon {
    width: 56px;
    height: 56px;
    overflow: hidden;
    position: absolute;
    z-index: 10;
}

.text-warning {
    color: #ffa22b !important;
}

.power-ribbon-top-left span {
    right: -5px;
    top: 1px;
    transform: rotate(-45deg);
}

.power-ribbon span {
    position: absolute;
    display: block;
    width: 82px;
    padding: 8px 0;
    color: #fff;
    font: 500 16px/1 Lato, sans-serif;
    text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    text-transform: capitalize;
    text-align: center;
}

bg-warning {
    background-color: #ffa22b !important;
    color: #fff;
}

.fs-16 {
    font-size: 16px !important;
}

.font-weight-semibold {
    font-weight: 600 !important;
}

h4 {
    margin-top: 10px;
}

ul {
    list-style-type: none;
}

.card a:hover {
    text-decoration: none;
}

input[type=text]:focus-visible, input[type=radio]:focus-visible {
    outline: 0;
}
img.login_right {
    height: auto;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    /* width: 70%;
    position: absolute;
    top: 65px; */
}

/* forgot password & create Account */

.submit_btn {
    margin: 5px 0 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    background-color: transparent !important;
    text-transform: capitalize !important;
    padding: 10px !important;
    box-shadow: none !important;
    border: 2px solid #51A2BD !important;
    color: #51A2BD !important;
}
.create_btn{
    margin: 5px 0 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    background-color: transparent !important;
    text-transform: capitalize !important;
    padding: 10px !important;
    box-shadow: none !important;
    border: 2px solid #51A2BD !important;
    color: #51A2BD !important;
}
.back_btn{
    margin: 5px 0 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    background-color: transparent !important;
    text-transform: capitalize !important;
    padding: 10px !important;
    box-shadow: none !important;
    color: #fff !important;
    background-color: #51A2BD !important;
}
span.MuiDivider-wrapper.css-qywfm8-MuiDivider-wrapper {
    padding: 5px !important;
    border: 1px solid #AEAEAE;
    border-radius: 3px;
    color: #AEAEAE;
    font-weight: 600;
    font-size: 16px;
}
.css-73qq0t-MuiDivider-root::before, .css-73qq0t-MuiDivider-root::after {
    border-top: 1px solid #AEAEAE !important;
}

@media screen and (max-width: 1024px) and (min-width: 900px) { 

    img.login_right {
        width: 350px;
    }
    .welcometext h3 {
        font-size: 25px;
    }
    #loginCode{
        font-size: 14px;
    }
    button[type="submit"], button.google_btn, .back_btn{
        font-size: 14px !important;
    }
    

}

@media screen and (max-width: 899px) and (min-width: 768px) { 



    img.login_right {
        width: 300px;
    }
    .welcometext h3 {
        font-size: 20px;
    }
    #loginCode, #password{
        font-size: 14px;
    }
    button[type="submit"], button.google_btn, .back_btn{
        font-size: 12px !important;
    }
    span.MuiDivider-wrapper.css-qywfm8-MuiDivider-wrapper {
        font-size: 10px;
    }
    .form-group p {
        font-size: 12px;
    }
    .forgot_link {
        font-size: 12px !important;
    }
    .welcometext {
        margin-bottom: 10px;
    }
    

}

@media screen and (max-width: 767px) and (min-width: 450px) { 



    .col.right {
        display: none;
    }
    .col.left {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
    }
    .welcometext h3 {
        font-size: 18px;
    }
    #loginCode, #password{
        font-size: 12px;
    }
    button[type="submit"], button.google_btn, .back_btn{
        font-size: 12px !important;
    }
    span.MuiDivider-wrapper.css-qywfm8-MuiDivider-wrapper {
        font-size: 10px;
    }
    .form-group p {
        font-size: 12px;
    }
    .forgot_link {
        font-size: 12px !important;
    }
    .welcometext {
        margin-bottom: 10px;
    }
    

}

@media screen and (max-width: 449px) and (min-width: 280px) { 



    .col.right {
        display: none;
    }
    .col.left {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
    }
    .welcometext h3 {
        font-size: 16px;
    }
    #loginCode, #password{
        font-size: 12px;
    }
    button[type="submit"], button.google_btn, .back_btn{
        font-size: 12px !important;
    }
    .form-group p {
        font-size: 12px;
    }
    .forgot_link {
        font-size: 12px !important;
    }
    .welcometext {
        margin-bottom: 10px;
    }
    span.MuiDivider-wrapper.css-qywfm8-MuiDivider-wrapper{
        font-size: 10px;
    }
    

}